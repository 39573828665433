
(function ($) {
	/* global jQuery */
	"use strict";
	const lr = {};
	lr.dom = {
		menuClose: $(".menu-close"),
		menuOpen: $(".menu-open"),
		navMain: $(".nav-main"),
		navMainLinks: $(".nav-main a"),
		header: $("header"),
		headerFixed: $("#header-fixed"),
		window: $(window),
		body: $("body")
	};

	lr.actions = {
		menuOpen: (e) => {
			e.preventDefault();
			if (lr.dom.body.hasClass("scrolled")) {
				lr.dom.headerFixed.css("height", "100%");
			}

			lr.dom.body.addClass("no-scroll");
			lr.dom.navMain.addClass("viz");
			setTimeout(function () {
				lr.dom.navMain.find(">ul").addClass("placedY");
				lr.dom.menuClose.addClass("placedY");
			}, 200);
		},
		menuClose: (e) => {
			e.preventDefault();
			lr.dom.body.removeClass("no-scroll");
			lr.dom.navMain.find(">ul").removeClass("placedY");
			lr.dom.menuClose.removeClass("placedY");
			setTimeout(function () {
				lr.dom.navMain.removeClass("viz");
				if (lr.dom.body.hasClass("scrolled")) {
					lr.dom.headerFixed.css("height", "auto");
				}
			}, 400);
		},
		windowScroll: () => {
			if (lr.dom.window.scrollTop() > 200) {
				lr.dom.body.addClass("scrolled");
			} else {
				lr.dom.body.removeClass("scrolled");
			}
		},
		showLoader: () => {
			lr.dom.body.removeClass("loaded");
		},
		hideLoader: () => {
			lr.dom.body.addClass("loaded");
		}
	};

	$(function () {
		let current = location.pathname;
		$(".nav-main li a").each(function () {
			let $this = $(this);
			// if the current path is like this link, make it active
			if ($this.attr("href").indexOf(current) !== -1) {
				$this.addClass("active");
			}
		});
	});

	//add classes to body via event listeners when window is done loading or scrolls
	lr.dom.window.on("load", () => {
		lr.actions.hideLoader();
		lr.actions.windowScroll();
	});
	lr.dom.window.on("scroll", () => {
		lr.actions.windowScroll();
	});

	lr.dom.menuOpen.on("click", (e) => {
		lr.actions.menuOpen(e);
	});
	lr.dom.menuClose.on("click", (e) => {
		lr.actions.menuClose(e);
	});

	lr.dom.navMainLinks.not(".menu-close").on("click", function (e) {
		const $this = $(this);
		const url = $this.attr("href");
		e.preventDefault();
		lr.actions.showLoader();
		setTimeout(function() {
			window.location.href = url;
		},1000);
	});
})(jQuery);
